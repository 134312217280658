import Link from "next/link"
import { memo } from "react"

import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import Config from "@spatialsys/web/config"
import { Heading, Text, cn } from "@spatialsys/web/ui"

type CaseStudyBrand =
  | "walmart"
  | "absolut"
  | "hugo-boss"
  | "bmw"
  | "jack-daniel"
  | "korea-tech"
  | "pigiama-kasama"
  | "utah-jazz"
  | "metaverse-beauty-week"
  | "vogue-singapore"
  | "cafe-joyeux"

type UseCaseMarqueeProps = {
  isSmallScreen: boolean
}

export const UseCaseMarquee = memo(function UseCaseMarquee({ isSmallScreen }: UseCaseMarqueeProps) {
  const ffQuery = useGetFeatureFlagsQuery()
  /**
   * Use Cases JSON structure:
   * [
   *  {
   *    title: string,
   *    subTitle: string,
   *    image: string, // @example: "/homepage/images/rebranding/use-case/absolut.webp"
   *    ratio: number,
   *    caseStudyBrand: string, // Should match the `CaseStudyBrand` type @example: "absolut"
   *  }
   * ]
   *
   * The order of the items in the array will determine the order of:
   * - the Use Cases marquee on the homepage
   * - the case study pages
   * - the case study page navigation
   *
   * TODO: Add the Walmart case study to the ConfigCat feature flag when it's ready to be published
   * {
   *   title: "Walmart",
   *   subTitle: "The Cultureverse",
   *   image: `${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/use-case/walmart.webp`,
   *   ratio: 1.83,
   *   caseStudyBrand: "walmart" as CaseStudyBrand,
   * }
   *
   */
  const useCasesData = ffQuery.data?.featureFlags.useCases ?? []

  // Parse the Use Cases JSON string and split into two arrays
  const allUseCases = typeof useCasesData === "string" ? JSON.parse(useCasesData) : []
  // Filter out invalid entries and ensure required fields are present
  const validUseCases = allUseCases.filter(
    (brand: any) =>
      brand?.title &&
      brand?.subTitle &&
      brand?.image &&
      brand?.ratio &&
      brand?.caseStudyBrand &&
      typeof brand.title === "string" &&
      typeof brand.subTitle === "string" &&
      typeof brand.image === "string" &&
      typeof brand.ratio === "number" &&
      typeof brand.caseStudyBrand === "string"
  )
  const firstRow = validUseCases.slice(0, Math.ceil(validUseCases.length / 2))
  const secondRow = validUseCases.slice(Math.ceil(validUseCases.length / 2))

  return (
    <div className={cn("group flex flex-col", isSmallScreen ? "gap-2" : "gap-4")}>
      <UseCaseMarqueeRow isSmallScreen={isSmallScreen} rowItems={firstRow} />
      <UseCaseMarqueeRow isSmallScreen={isSmallScreen} rowItems={secondRow} isReverse />
    </div>
  )
})

type UseCaseMarqueeRowProps = {
  isSmallScreen: boolean
  rowItems: {
    title: string | JSX.Element
    subTitle?: string | JSX.Element
    image: string
    ratio: number
    caseStudyBrand: CaseStudyBrand
  }[]
  isReverse?: boolean
}

export const UseCaseMarqueeRow = memo(function UseCaseMarqueeRow({
  isSmallScreen,
  rowItems,
  isReverse,
}: UseCaseMarqueeRowProps) {
  return (
    <div className="relative flex h-[171px] w-[100vw] max-w-full items-center overflow-hidden sm:h-[243px]">
      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 w-32 bg-gradient-to-r from-background via-background/80 via-30% to-transparent sm:w-48" />
      <div className="absolute flex h-[168px] w-max sm:h-[240px]">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            aria-hidden={index > 0}
            className={cn(
              isReverse ? "animate-marquee-reverse" : "animate-marquee",
              "duration-3500 sm:duration-4000 group-hover:paused"
            )}
          >
            {rowItems.map(({ title, subTitle, image, ratio, caseStudyBrand }) => {
              return (
                <Link
                  key={`${caseStudyBrand}-${index}`}
                  href={`/case-studies/${caseStudyBrand}`}
                  className="group/item relative mx-1 inline-block h-[168px] overflow-hidden rounded-3xl sm:mx-2 sm:h-[240px]"
                  style={{ width: isSmallScreen ? `${Math.round(168 * ratio)}px` : `${Math.round(240 * ratio)}px` }}
                >
                  <img
                    alt={`${title}`}
                    src={`${Config.PUBLIC_ASSETS_BASE_URL}${image}`}
                    loading="lazy"
                    className="size-full object-cover transition-transform duration-500 group-hover/item:scale-110"
                  />
                  <div className="absolute bottom-0 h-[70px] w-full bg-gradient-to-t from-[#111] from-[6.46%] to-transparent sm:h-[100px]" />
                  <div className="absolute bottom-3 left-6">
                    <Heading className="text-sm sm:text-lg">{title}</Heading>
                    {subTitle && <Text className="text-xs sm:text-sm">{subTitle}</Text>}
                  </div>
                </Link>
              )
            })}
          </div>
        ))}
      </div>
      <div className="pointer-events-none absolute inset-y-0 right-0 z-10 w-32 bg-gradient-to-l from-background via-background/80 via-30% to-transparent sm:w-48" />
    </div>
  )
})
